.multi-range-slider-container {
    max-width: 600px;
    margin: auto;
  }
  .multi-range-slider {
    box-shadow: none;
    border: 0;
  }
  .multi-range-slider .ruler {
    display: none;
  }
