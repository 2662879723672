$background-color_1: grey;

.SearchBar {
  ul {
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
    position: absolute;
    background-color: white;
    z-index: 999;
    overflow-y: auto;
    max-height: 300px;
    max-width: 370px;
    min-width: 370px;
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 10px;
  }
  li {
    &::before {
      content: "";
    }
    padding: 10px 5px;
    cursor: pointer;
    list-style: none;
    &:hover {
      background-color: $theme-color;
      text-decoration: none;
      border-radius: 10px;
      list-style: none;
      color: white;
    }
  }
}
.multi-range-slider {
  .bar-inner {
    max-height: 5px;
  }
}
