/*---- 18. Shop page  -------*/

.shop-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{$xs-layout} {
    display: block;
  }
  @media #{$sm-layout} {
    display: flex;
  }
  .select-shoing-wrap {
    display: flex;
    @media #{$xs-layout} {
      display: block;
    }
    @media #{$sm-layout} {
      display: flex;
    }
    .shop-select {
      margin-right: 50px;
      select {
        font-size: 14px;

        min-width: 143px;
        padding: 5px;

        color: #606060;
        border: 1px solid #e6e6e6;
      }
    }

    p {
      margin: 0;

      color: #606060;
      @media #{$xs-layout} {
        margin: 5px 0 10px;
      }
      @media #{$sm-layout} {
        margin: 0;
      }
    }
  }
  .shop-tab {
    a,
    button {
      font-size: 18px;

      margin-left: 20px;
      padding: 0;

      color: #606060;
      border: none;
      background: none;
      &:first-child {
        margin-left: 0;
      }
      &.active {
        color: #f8d038;
      }
    }
  }
}

.shop-list-wrap {
  .product-wrap {
    &:hover .hover-img {
      visibility: inherit;

      transform: none;

      opacity: inherit;
    }
  }
  .shop-list-content {
    @media #{$xs-layout} {
      margin-top: 20px;
    }
    @media #{$sm-layout} {
      margin-top: 0;
    }
    h3 {
      font-size: 24px;

      margin: 0;

      color: #010101;
      @media #{$xs-layout} {
        font-size: 20px;
        line-height: 20px;
      }
      a {
        color: #010101;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .product-list-price {
      margin: 13px 0 22px;
      @media #{$xs-layout} {
        margin: 13px 0 13px;
      }
      @media #{$md-layout} {
        margin: 13px 0 19px;
      }
      span {
        font-size: 24px;
        line-height: 1;

        display: inline-block;

        color: #000;
        &.old {
          font-size: 18px;

          margin-left: 15px;

          text-decoration: line-through;

          color: #cfcfcf;
        }
      }
    }
    .rating-review {
      display: flex;
      align-items: center;
      .product-list-rating {
        i {
          font-size: 17px;

          margin: 0 3px 0;

          color: #5f5d5d;
          &.yellow {
            color: #ffa900;
          }
        }
      }
      a {
        font-size: 15px;
        line-height: 1;

        position: relative;

        margin-left: 40px;

        color: #ffcf75;
        &:before {
          position: absolute;
          top: 0;
          left: -21px;

          width: 1px;
          height: 15px;

          content: "";

          background-color: #d1d1d1;
        }
      }
    }
    p {
      font-size: 15px;
      line-height: 28px;

      margin: 15px 0 40px;

      color: #8f8f8f;
      @media #{$xs-layout} {
        margin: 12px 0 20px;
      }
      @media #{$md-layout} {
        margin: 12px 0 20px;
      }
      @media #{$lg-layout} {
        margin: 15px 0 20px;
      }
    }
    .shop-list-btn {
      a,
      button {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;

        z-index: 1;

        display: inline-block;

        padding: 14px 32px 15px;

        text-transform: uppercase;

        color: #fff;
        border: 1px solid transparent;
        background-color: #343538;
        @media #{$xs-layout} {
          font-size: 13px;

          padding: 10px 20px 11px;
        }
        &:hover {
          border: 1px solid $theme-color;
        }
      }
    }
  }
  &:hover .product-wrap .hover-img {
    visibility: visible;

    transform: translate(-50%, 0);

    opacity: 1;
  }
}
.pro-pagination-style {
  @media #{$xs-layout} {
    &.mt-30 {
      margin-top: 10px;
    }
  }
  ul {
    li {
      display: inline-block;

      margin: 0 4px;
      a,
      button {
        font-size: 16px;
        line-height: 43px;

        display: inline-block;

        width: 43px;
        height: 43px;
        padding: 0;

        text-align: center;

        color: $theme-color;
        border: none;
        border-radius: 100% !important;
        background: none;
        box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1);
        &:hover {
          color: #fff;
          background-color: $theme-color;
        }
      }
      a.active {
        color: #fff;
        background-color: $theme-color;
        box-shadow: none;
        &:hover {
          background-color: #333;
        }
      }
      a.prev,
      a.next {
        font-size: 17px;

        color: $theme-color;
        background-color: #f6f6f6;
        box-shadow: none;
        &:hover {
          color: #fff;
          background-color: $theme-color;
        }
      }

      &.page-item {
        &.active {
          .page-link {
            border-color: $theme-color;
            background-color: $theme-color;
            box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;
            &:focus {
              box-shadow: none;
            }
          }
        }
        .page-link {
          box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

@media #{$xs-layout} {
  .shop-area.pb-100 {
    padding-bottom: 45px;
  }
}

.list2-col-style {
  .shop-list-wrap {
    .shop-list-content {
      @media #{$xl-layout} {
        h3 {
          font-size: 20px;
          line-height: 20px;
        }
        .product-list-price {
          margin: 13px 0 10px;
        }
        p {
          margin: 6px 0 20px;
        }
        .shop-list-btn {
          a {
            font-size: 13px;

            padding: 10px 24px 11px;
          }
        }
      }
      @media #{$lg-layout} {
        h3 {
          font-size: 20px;
          line-height: 20px;
        }
        .product-list-price {
          margin: 13px 0 10px;
        }
        p {
          margin: 6px 0 20px;
        }
        .shop-list-btn {
          a {
            font-size: 13px;

            padding: 10px 20px 11px;
          }
        }
      }
    }
  }
}

.grid-sizer {
  width: 25%;
}

.shop-bottom-area {
  .col-xl-4 {
    transition: 0.5s;
  }
  .grid {
    .shop-list-wrap {
      display: none;
    }

    &.two-column {
      .col-xl-4 {
        flex: 1 0 50%;

        max-width: 50%;

        transition: 0.5s;
      }
    }
  }
  .list {
    .product-wrap {
      display: none;
    }
    &.two-column {
      & > .col-xl-4 {
        flex: 1 0 50%;

        max-width: 50%;

        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
        @media #{$md-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
        @media #{$xs-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
      }
    }

    & > .col-xl-4 {
      flex: 1 0 100%;

      max-width: 100%;

      transition: 0.5s;
    }
  }
}

.shop-list-wishlist,
.shop-list-compare {
  button {
    font-size: 20px;

    padding: 0;

    border: none;
    background: none;
    &:hover,
    &.active {
      color: $theme-color;
    }
  }
}

.shop-area {
  .container-fluid {
    padding: 0 70px;
    @media #{$xl-layout} {
      padding: 0 15px;
    }
    @media #{$lg-layout} {
      padding: 0 30px;
    }
    @media #{$md-layout} {
      padding: 0 40px;
    }
    @media #{$xs-layout} {
      padding: 0 15px;
    }
  }
}

$color_1: #fff;
$font-family_1: "Font Awesome 5 Free";
$background-color_1: rgb(167, 73, 255);
$border-color_1: #7b7b7b;

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 18px;
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &:focus {
      ~ {
        .control__indicator {
          background: #ccc;
        }
      }
    }
    &:checked {
      ~ {
        .control__indicator {
          background: rgb(167, 73, 255);
          &:after {
            display: block;
            color: $color_1;
          }
        }
      }
      &:focus {
        ~ {
          .control__indicator {
            background: rgb(167, 73, 255);
          }
        }
      }
    }
    &:disabled {
      ~ {
        .control__indicator {
          background: #e6e6e6;
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }
  }
  &:hover {
    input {
      ~ {
        .control__indicator {
          background: #ccc;
        }
      }
      &:not([disabled]) {
        &:checked {
          ~ {
            .control__indicator {
              background: rgb(167, 73, 255);
            }
          }
        }
      }
    }
  }
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  &:after {
    font-family: $font-family_1;
    content: "\f058";
    position: absolute;
    display: none;
    font-size: 14px;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
  }
}
.control--radio {
  .control__indicator {
    border-radius: 50%;
  }
}
.control--checkbox {
  .control__indicator {
    &:after {
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
  input {
    &:disabled {
      ~ {
        .control__indicator {
          &:after {
            border-color: $border-color_1;
          }
        }
      }
      &:checked {
        ~ {
          .control__indicator {
            background-color: $background-color_1;
            opacity: 0.2;
          }
        }
      }
    }
  }
}
.filter-button__container {
  cursor: pointer;
  left: 32px;
  position: fixed;
  text-align: center;
  top: 42%;
  z-index: 2;
  .icon-button__toolIcon {
    background-color: $theme-color;
    height: 60px;
    opacity: 1;
    width: 60px;
    // background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 50%;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 5%);
    margin: 10px 5px;
    padding: 10px;
    position: relative;
    .filter-button__icon {
      background: url("https://swag.com/images-webpack/icon-filters.11fe5246f5f0616144df..svg") no-repeat 50%;
      background-size: 25px 25px;
      transform: rotate(90deg);
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .filter-button__label{
    color: $theme-color;
    font-weight: 800;
    font-size: 16px;
  }
}
